import { Trans } from "react-i18next";
import { t } from "i18next";
import Dialog from "@nesvet/missing-mui4-components/Dialog";
import LoadingButton from "@nesvet/missing-mui4-components/LoadingButton";
import TextField from "@material-ui/core/esm/TextField";
import DoneIcon from "@material-ui/icons/esm/Done";
import Autocomplete from "@material-ui/lab/esm/Autocomplete";
import { inSite } from "$app";
import { HelperTextButton } from "$components/HelperTextButton";
import { HelperTextIcon } from "$components/HelperTextIcon";
import { RolesInput } from "$components/RolesInput";
import copy from "$lib/copy";
import generatePassword from "$lib/generatePassword";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, typography }) => ({
	
	root: {},
	
	content: {
		display: "flex",
		flexFlow: "column nowrap"
	},
	
	row: {
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "flex-end",
		
		"&:not(:last-child)": {
			marginBottom: spacing(1)
		},
		
		"& > *": {
			flex: "1 1 0",
			
			"&:not(:last-child)": {
				marginRight: spacing(1.5)
			}
		}
	},
	
	password: {
		
		"& input": {
			fontFamily: "monospace, sans-serif",
			fontSize: typography.htmlFontSize
		}
	}
	
}), "AddPersonDialog");


export class AddPersonDialog extends Dialog {
	constructor(props) {
		super(props, {
			email: "",
			emailError: null,
			isEmailOk: false,
			password: "",
			isPasswordGenerated: false,
			isPasswordCopied: false,
			firstName: "",
			middleName: "",
			lastName: "",
			roleIds: [],
			isRolesEmpty: false,
			org: null,
			isPending: false
		});
		
	}
	
	handleOpenState = () => ({
		password: generatePassword(),
		isPasswordGenerated: true,
		isPasswordCopied: false
	});
	
	title = t("Users.People.AddDialog.dialogTitle");
	
	maxWidth = "sm";
	
	closeButtonLabel = t("Users.People.AddDialog.close");
	
	#emailErrorMessages = {
		empty: "Нужно заполнить",
		notemail: "Не похоже на почту",
		exists: "Такой пользователь уже есть",
		unknown: "Неизвестная ошибка"
	};
	
	#handleEmailInput = ({ target: { value: email } }) => {
		this.setState({ email, emailError: null, isEmailOk: false });
		
		if (email)
			inSite.ws.sendRequest("users.people.check-email", email, error => this.setState({ isEmailOk: !error }));
		
	};
	
	#handleEmailBlur = ({ target: { value: email } }) => {
		if (email)
			inSite.ws.sendRequest("users.people.check-email", email, error => this.setState({ emailError: error?.tag ?? null, isEmailOk: !error }));
		else
			this.setState({ emailError: "empty", isEmailOk: false });
		
	};
	
	#handlePasswordInput = ({ target: { value: password } }) => this.setState({
		password,
		isPasswordGenerated: false,
		isPasswordCopied: false
	});
	
	#handlePasswordFocus = event => event.target.select();
	
	#handlePasswordGenerateClick = () => this.setState({
		password: generatePassword(),
		isPasswordGenerated: true,
		isPasswordCopied: false
	});
	
	#handlePasswordCopyClick = async () => {
		try {
			await copy(this.state.password);
			this.setState({ isPasswordCopied: true });
		} catch {}
		
	};
	
	#handleInput = ({ target: { name, value } }) => this.setState({ [name]: value.trim() });
	
	#handleRolesChange = roleIds => this.setState({ roleIds, isRolesEmpty: !roleIds.length });
	
	#handleOrgGetOptionLabel = org => org.title;
	
	#handleOrgRenderInput = params => (
		<TextField {...params} label={t("Users.People.AddDialog.org")} helperText=" " />
	);
	
	#handleOrgChange = (_, org) => this.setState({ org });
	
	#handleAddClick = async () => {
		
		const { email, password, roleIds, firstName, middleName, lastName, org } = this.state;
		
		if (roleIds.length) {
			this.setState({ isPending: true });
			
			try {
				await inSite.ws.sendRequest("users.people.add", {
					email,
					password,
					roles: roleIds,
					name: {
						first: firstName,
						middle: middleName,
						last: lastName
					},
					org: org?._id ?? null
				});
				
				this.setState({ isPending: false });
				
				this.close();
			} catch (error) {
				this.setState({
					emailError: error.tag in this.#emailErrorMessages ? error.tag : "unknown",
					isPending: false
				});
			}
		} else
			this.setState({ isRolesEmpty: true });
		
	};
	
	
	renderContent() {
		
		const {
			email,
			emailError,
			isEmailOk,
			password,
			isPasswordGenerated,
			isPasswordCopied,
			firstName,
			middleName,
			lastName,
			roleIds,
			isRolesEmpty,
			org
		} = this.state;
		
		return (
			<>
				<div className={classes.row}>
					
					<TextField
						label={t("Users.People.AddDialog.email")}
						type="email"
						value={email}
						autoComplete="off"
						autoFocus
						error={!!emailError}
						helperText={emailError ? this.#emailErrorMessages[emailError] : isEmailOk ? (
							<HelperTextIcon color="primary" Icon={DoneIcon} />
						) : " "}
						onBlur={this.#handleEmailBlur}
						onInput={this.#handleEmailInput}
					/>
					
					<TextField
						className={classes.password}
						label={t("Users.People.AddDialog.password")}
						value={password}
						autoComplete="off"
						helperText={(
							<>
								{isPasswordGenerated ? (
									<span>
										<Trans i18nKey="Users.People.AddDialog.newPasswordGenerated" />
										.
									</span>
								) : (
									<HelperTextButton onClick={this.#handlePasswordGenerateClick}>
										Сгенерировать новый
									</HelperTextButton>
								)}
								{isPasswordCopied ? (
									<span>
										&nbsp;Скопировано
									</span>
								) : password && (
									<HelperTextButton onClick={this.#handlePasswordCopyClick}>
										<Trans i18nKey="Users.People.AddDialog.copy" />
									</HelperTextButton>
								)}
							</>
						)}
						spellCheck="false"
						onFocus={this.#handlePasswordFocus}
						onInput={this.#handlePasswordInput}
					/>
				
				</div>
				
				<div className={classes.row}>
					
					<TextField
						label={t("Users.People.AddDialog.firstName")}
						name="firstName"
						value={firstName}
						autoComplete="off"
						helperText=" "
						onInput={this.#handleInput}
					/>
					
					<TextField
						label={t("Users.People.AddDialog.middleName")}
						name="middleName"
						value={middleName}
						autoComplete="off"
						helperText=" "
						onInput={this.#handleInput}
					/>
					
					<TextField
						label={t("Users.People.AddDialog.lastName")}
						name="lastName"
						value={lastName}
						autoComplete="off"
						helperText=" "
						onInput={this.#handleInput}
					/>
				
				</div>
				
				<div className={classes.row}>
					
					<Autocomplete
						value={org}
						getOptionLabel={this.#handleOrgGetOptionLabel}
						noOptionsText={t("Users.People.AddDialog.orgNoOptionsText")}
						options={inSite.user.slaveOrgs}
						renderInput={this.#handleOrgRenderInput}
						onChange={this.#handleOrgChange}
					/>
					
					<RolesInput
						value={inSite.roles.getAll(roleIds)}
						InputProps={{
							error: isRolesEmpty,
							helperText: isRolesEmpty ? "Нужно указать" : " "
						}}
						options={inSite.roles.sorted}
						onChange={this.#handleRolesChange}
					/>
				
				</div>
			</>
		);
	}
	
	renderActions() {
		
		const {
			isEmailOk,
			password,
			isPending
		} = this.state;
		
		return (
			<LoadingButton
				color="primary"
				disableElevation
				pending={isPending}
				variant="contained"
				onClick={this.#handleAddClick}
				disabled={!isEmailOk || !password}
			>
				<Trans i18nKey="Users.People.AddDialog.add" />
			</LoadingButton>
		);
	}
	
}
