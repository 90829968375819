export const ru = {
	
	Login: {
		email: "Электронная почта",
		password: "Пароль",
		download: "Скачать",
		"log-in": "Войти",
		
		errors: {
			usernotfound: "Нет такого пользователя",
			incorrectpassword: "Неверный пароль",
			userisnotabletologin: "У пользователя нет полномочий для логина",
			unknown: "Неизвестная ошибка"
		}
	},
	
	Application: {
		tabLabel: "Приложение"
	},
	
	Users: {
		tabLabel: "Пользователи",
		header: "Пользователи",
		
		People: {
			title: "Люди",
			
			AddDialog: {
				dialogTitle: "Новый пользователь",
				email: "Электронная почта",
				password: "Пароль",
				newPasswordGenerated: "Сгенерирован новый",
				copy: "Копировать",
				firstName: "Имя",
				middleName: "Отчество",
				lastName: "Фамилия",
				org: "Организация",
				orgNoOptionsText: "Пусто",
				add: "Добавить",
				close: "Закрыть"
			}
		},
		
		Orgs: {
			title: "Организации"
		},
		
		Roles: {
			title: "Роли"
		}
	}
};
