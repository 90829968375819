export const en = {
	
	Login: {
		email: "E-mail",
		password: "Password",
		download: "Download",
		"log-in": "Log in",
		
		errors: {
			usernotfound: "No such user",
			incorrectpassword: "Wrong password",
			userisnotabletologin: "User is unable to login",
			unknown: "Unknown error"
		}
	},
	
	Application: {
		tabLabel: "Application"
	},
	
	Users: {
		tabLabel: "Users",
		header: "Users",
		
		People: {
			title: "People",
			
			AddDialog: {
				title: "New user",
				email: "E-mail",
				password: "Password",
				newPasswordGenerated: "Generated",
				copy: "Copy",
				firstName: "First name",
				middleName: "Middle name",
				lastName: "Last name",
				org: "Organization",
				orgNoOptionsText: "Empty",
				add: "Add",
				close: "Close"
			}
		},
		
		Orgs: {
			title: "Organizations"
		},
		
		Roles: {
			title: "Roles"
		}
	}
};
