export const ru = {
	
	EMail: "Электронная почта",
	Password: "Пароль",
	Copy: "Копировать",
	Add: "Добавить",
	Download: "Скачать",
	Close: "Закрыть",
	
	Login: {
		LogIn: "Войти",
		
		errors: {
			usernotfound: "Нет такого пользователя",
			incorrectpassword: "Неверный пароль",
			userisnotabletologin: "У пользователя нет полномочий для логина",
			unknown: "Неизвестная ошибка"
		}
	},
	
	Application: {
		tabLabel: "Приложение"
	},
	
	Users: {
		tabLabel: "Пользователи",
		header: "Пользователи",
		
		People: {
			title: "Люди",
			NoOrganization: "Без организации",
			
			AddDialog: {
				dialogTitle: "Новый пользователь",
				NewPasswordGenerated: "Сгенерирован новый",
				FirstName: "Имя",
				MiddleName: "Отчество",
				LastName: "Фамилия",
				Org: "Организация",
				orgNoOptionsText: "Пусто"
			}
		},
		
		Orgs: {
			title: "Организации"
		},
		
		Roles: {
			title: "Роли"
		}
	}
};
