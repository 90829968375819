import { Component } from "react";
import { Trans } from "react-i18next";
import clsx from "clsx";
import { t } from "i18next";
import LinuxIcon from "@nesvet/missing-mui4-components/icons/Linux";
import LoginIcon from "@nesvet/missing-mui4-components/icons/Login";
import WindowsIcon from "@nesvet/missing-mui4-components/icons/Windows";
import LoadingButton from "@nesvet/missing-mui4-components/LoadingButton";
import Link from "@material-ui/core/esm/Link";
import TextField from "@material-ui/core/esm/TextField";
import Tooltip from "@material-ui/core/esm/Tooltip";
import Typography from "@material-ui/core/esm/Typography";
import AppleIcon from "@material-ui/icons/esm/Apple";
import { inSite } from "$app";
import { createStyles } from "$styles";


const classes = createStyles(({ spacing, alpha, palette, transitions }) => ({
	
	root: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		backgroundColor: alpha(palette.background.paper, .95)
	},
	
	topLayout: {
		flex: "1 1 0",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		overflow: "hidden"
	},
	
	logo: {
		width: 256,
		height: 256,
		verticalAlign: "top"
	},
	
	layout: {
		flex: "1 1 0",
		display: "flex",
		overflow: "hidden",
		flexFlow: "row nowrap",
		alignItems: "flex-start",
		gap: spacing(10)
	},
	
	left: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		
		"& $header": {
			marginBottom: spacing(3)
		}
	},
	
	right: {
		flex: "1 1 auto",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		
		"& $header": {
			marginBottom: spacing(3)
		}
	},
	
	downloads: {
		flex: `1 0 ${spacing(17)}px`,
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center"
	},
	
	header: {},
	
	email: {
		width: 200
	},
	
	password: {
		width: 200
	},
	
	button: {
		marginTop: spacing(2),
		paddingRight: spacing(1),
		paddingLeft: spacing(1),
		minWidth: "auto"
	},
	
	downloadsCurrentPlatform: {
		marginBottom: spacing(2),
		
		"& $downloadsIcon": {
			width: spacing(10),
			height: spacing(10),
			opacity: 1
		}
	},
	
	downloadsIcon: {
		width: spacing(6),
		height: spacing(6),
		margin: spacing(1),
		color: palette.primary.main,
		opacity: .5,
		transitionProperty: "opacity, transform",
		transitionDuration: transitions.duration.shortest,
		
		"&:hover": {
			opacity: 1,
			transform: "scale(1.1)"
		}
	},
	
	copyright: {
		flex: "0 0 auto",
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		paddingBottom: spacing(3),
		color: palette.text.disabled
	},
	
	"@media only screen and (max-width: 800px)": {
		
		logo: {
			width: 192,
			height: 192
		}
	},
	
	"@media only screen and (max-width: 600px)": {
		
		topLayout: {
			pointerEvents: "none"
		},
		
		layout: {
			
			"& $header": {
				display: "none"
			}
		},
		
		logo: {
			width: 128,
			height: 128
		},
		
		left: {
			display: "none"
		},
		
		copyright: {
			paddingBottom: spacing(2)
		}
	}
	
}), "LoginDialog");


const copyrightYear = 2020;
const currentYear = (new Date()).getFullYear();


export class LoginDialog extends Component {
	
	state = {
		email: "",
		emailError: null,
		password: "",
		passwordError: null,
		isAutoFilled: false,
		isLogging: false
	};
	
	#platformString = /Win/.test(navigator.platform) ? "windows" : /Mac/.test(navigator.platform) ? "macos" : /Linux/.test(navigator.platform) ? "linux" : null;
	
	#handleEmailInput = event => this.setState({
		email: event.target.value,
		emailError: null,
		passwordError: null,
		isAutoFilled: false
	});
	
	#handlePasswordInput = event => this.setState({
		password: event.target.value,
		emailError: null,
		passwordError: null,
		isAutoFilled: false
	});
	
	#handleKeyDown = event => event.keyCode === 13 && this.#submit();
	
	#handleButtonClick = () => this.#submit();
	
	#emailInputNode;
	#handleEmailRef = node => (this.#emailInputNode = node?.querySelector("input"));
	
	#passwordInputNode;
	#handlePasswordRef = node => (this.#passwordInputNode = node?.querySelector("input"));
	
	#errorMessages = {
		usernotfound: t("Login.errors.usernotfound"),
		incorrectpassword: t("Login.errors.incorrectpassword"),
		userisnotabletologin: t("Login.errors.userisnotabletologin")
	};
	
	#handleLogin = error => error.message && this.setState({
		emailError: error.message === "usernotfound" || error.message === "userisnotabletologin" ? error.message : null,
		passwordError: error.message === "incorrectpassword" ? error.message : null,
		isLogging: false
	});
	
	#submit() {
		
		const { email, password } = this.state;
		
		if (email && password) {
			this.setState({ isLogging: true });
			
			inSite.ws.sendRequest("login", email, password, this.#handleLogin);
		}
		
	}
	
	render() {
		
		const { email, emailError, password, passwordError, isLogging, isAutoFilled } = this.state;
		
		const downloads = [
			[ "macos", AppleIcon, "macOS" ],
			[ "windows", WindowsIcon, "Windows" ],
			[ "linux", LinuxIcon, "Linux" ]
		];
		
		if (this.#platformString) {
			const platform = downloads.find(([ anotherPlatform ]) => anotherPlatform === this.#platformString);
			
			downloads.splice(downloads.indexOf(platform), 1);
			downloads.splice(1, 0, platform);
		}
		
		const isLoginButtonDisabled = isLogging || (!(email && password) && !isAutoFilled);
		
		return (
			<div className={classes.root}>
				
				<div className={classes.topLayout}>
					<a href="/download">
						<img className={classes.logo} alt="Roadmap" src="/logo.png" />
					</a>
				</div>
				
				<div className={classes.layout}>
					
					<div className={classes.left}>
						
						<Typography className={classes.header} variant="h4">
							<Trans i18nKey="Login.download" />
						</Typography>
						
						<div className={classes.downloads}>
							{downloads.map(([ platformString, Icon, label ]) => {
								const isCurrent = platformString === this.#platformString;
								
								return (
									<Tooltip arrow title={label} key={platformString}>
										<Link className={clsx(isCurrent && classes.downloadsCurrentPlatform)} download href={`/download${isCurrent ? "" : `/${platformString}`}`}>
											<Icon className={classes.downloadsIcon} />
										</Link>
									</Tooltip>
								);
							})}
						</div>
					
					</div>
					
					<div className={classes.right}>
						
						<Typography className={classes.header} variant="h4">
							<Trans i18nKey="Login.log-in" />
						</Typography>
						
						<TextField
							className={classes.email}
							label={`${t("Login.email")}`}
							value={email}
							autoComplete="email"
							autoFocus
							error={!!emailError}
							helperText={this.#errorMessages[emailError] || " "}
							onInput={this.#handleEmailInput}
							onKeyDown={this.#handleKeyDown}
							ref={this.#handleEmailRef}
						/>
						
						<TextField
							className={classes.password}
							label={`${t("Login.password")}`}
							type="password"
							value={password}
							autoComplete="current-password"
							error={!!passwordError}
							helperText={this.#errorMessages[passwordError] || " "}
							onInput={this.#handlePasswordInput}
							onKeyDown={this.#handleKeyDown}
							ref={this.#handlePasswordRef}
						/>
						
						<LoadingButton
							className={classes.button}
							color={isLoginButtonDisabled ? "default" : "primary"}
							pending={isLogging}
							variant={isLoginButtonDisabled ? "text" : "contained"}
							onClick={this.#handleButtonClick}
							disabled={isLoginButtonDisabled}
						>
							<LoginIcon />
						</LoadingButton>
					
					</div>
				
				</div>
				
				<div className={classes.copyright}>
					©
					{" "}
					{copyrightYear}
					{copyrightYear !== currentYear && `–${currentYear}`}
					{" "}
					Roadmap
				</div>
			
			</div>
		);
	}
	
	componentDidMount() {
		
		this.autoFillInterval = setInterval(() => {
			
			if (document.body.querySelector(":-webkit-autofill")) {
				clearInterval(this.autoFillInterval);
				clearTimeout(this.autoFillTimeout);
				this.setState({ isAutoFilled: true });
			}
			
		}, 200);
		
		this.autoFillTimeout = setTimeout(() => clearInterval(this.autoFillInterval), 5000);
		
	}
	
	componentDidUpdate() {
		
		if (this.state.emailError) {
			this.#emailInputNode.focus();
			this.#emailInputNode.select();
		}
		
		if (this.state.passwordError) {
			this.#passwordInputNode.focus();
			this.#passwordInputNode.select();
		}
		
	}
	
	componentWillUnmount() {
		
		clearInterval(this.autoFillInterval);
		clearTimeout(this.autoFillTimeout);
		
	}
	
}
