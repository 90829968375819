export const en = {
	
	EMail: "E-mail",
	Password: "Password",
	Copy: "Copy",
	Add: "Add",
	Download: "Download",
	Close: "Close",
	
	Login: {
		LogIn: "Log in",
		
		errors: {
			usernotfound: "No such user",
			incorrectpassword: "Wrong password",
			userisnotabletologin: "User is unable to login",
			unknown: "Unknown error"
		}
	},
	
	Application: {
		tabLabel: "Application"
	},
	
	Users: {
		tabLabel: "Users",
		header: "Users",
		
		People: {
			title: "People",
			NoOrganization: "No organization",
			
			AddDialog: {
				dialogTitle: "New user",
				NewPasswordGenerated: "Generated",
				FirstName: "First name",
				MiddleName: "Middle name",
				LastName: "Last name",
				Org: "Organization",
				orgNoOptionsText: "Empty"
			}
		},
		
		Orgs: {
			title: "Organizations"
		},
		
		Roles: {
			title: "Roles"
		}
	}
};
