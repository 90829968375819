import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./en";
import { ru } from "./ru";


i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === "development",
		fallbackLng: "en",
		interpolation: {
			escapeValue: false
		},
		resources: {
			en: { translation: en },
			ru: { translation: ru }
		}
	});


export { default as i18n } from "i18next";
