import { Component } from "react";
import { Trans } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import clsx from "clsx";
import { t } from "i18next";
import Typography from "@material-ui/core/esm/Typography";
import PeopleAltIcon from "@material-ui/icons/esm/PeopleAlt";
import { inSite } from "$app";
import { Loading } from "$components/Loading";
import { SectionSubnav } from "$components/SectionSubnav";
import { createStyles } from "$styles";
import { Orgs } from "./Orgs";
import { People } from "./People";
import { Roles } from "./Roles";


const classes = createStyles(({ spacing }) => ({
	
	root: {
		position: "relative",
		display: "flex",
		flexFlow: "column nowrap"
	},
	
	header: {
		marginBottom: spacing(5)
	}
	
}), "Users");


export class Users extends Component {
	static sectionName = "users";
	static label = t("Users.tabLabel");
	static Icon = PeopleAltIcon;
	
	constructor(props) {
		super(props);
		
		inSite.usersSubscriptionGroup.extend();
		
	}
	
	render() {
		
		const { className } = this.props;
		
		return (
			<section className={clsx(classes.root, className)}>
				
				<Typography className={classes.header} variant="h1">
					<span>
						<Trans i18nKey="Users.header" />
					</span>
				</Typography>
				
				<SectionSubnav
					links={[
						[ "/users/people", t("Users.People.title") ],
						[ "/users/orgs", t("Users.Orgs.title") ],
						[ "/users/roles", t("Users.Roles.title") ]
					]}
				/>
				
				{inSite.usersSubscriptionGroup.isExtended ? (
					<Switch>
						<Route exact path="/users">
							<Redirect to="/users/people" />
						</Route>
						<Route path="/users/people">
							<People />
						</Route>
						<Route path="/users/orgs">
							<Orgs />
						</Route>
						<Route path="/users/roles">
							<Roles />
						</Route>
					</Switch>
				) : (
					<Loading />
				)}
			
			</section>
		);
	}
	
	#handleUsersUpdate = () => this.forceUpdate();
	
	componentDidMount() {
		
		inSite.usersSubscriptionGroup.on("update", this.#handleUsersUpdate);
		
		inSite.usersSubscriptionGroup.extend();
		
	}
	
	componentWillUnmount() {
		
		inSite.usersSubscriptionGroup.off("update", this.#handleUsersUpdate);
		
		inSite.usersSubscriptionGroup.unextend();
		
	}
	
}
